//basic bootstrap variables overriding
$icon-font-path: '../../../node_modules/bootstrap-sass/assets/fonts/bootstrap/';

$navbar-height: 48px;
$font-family-sans-serif: 'Arial', sans-serif;
$font-size-table-cell: 12px;
$navbar-default-link-color: $white;
$navbar-default-link-hover-color: $white;
$navbar-default-link-active-color: $white;
$navbar-default-link-active-bg: $primary;
$navbar-default-link-hover-bg: $primary;

$gray-dark: $text;
$border-radius-base: 2px;
$brand-primary: $primary;
$brand-success: $success;
$brand-info: $info;
$brand-warning: $warning;
$brand-danger: $danger;

// Table color styles configuration
$table-bg-hover: $primary-1;
$input-border: $default-border-color;
$table-border-color: $default-border-color;

// select2 that replace standard <select> boxes
$state-success-text: $brand-success;
$state-success-bg: $success-1;
$state-info-text: $info-8;
$state-info-bg: $info-1;
$state-warning-text: $brand-warning;
$state-warning-bg: $warning-1;
$state-danger-text: $brand-danger;
$state-danger-bg: $danger-1;

//side bar settings

$sidenav-width: 48px;
$main-nav-height: 48px;

//Spreading
$table-gutter: 10px;
$border-width: 1px;

$period-selector-box-height: 140px;

$border-radius: .25rem !default; // override v5 default with previous value

// bootstrap 3 variables, missing in bootstrap 4+
$border-radius-large:       6px !default;
$border-radius-small:       3px !default;
$body-color: #464646;


// opt-out of bootstrap 5s change to underline <a> tags at all times - revert to v4 behavior of hover underline on hover
$link-decoration: none;
$link-hover-decoration: underline;

// select-2 required vars
$caret-width-base:          4px !default;
$caret-width-large:         5px !default;
$cursor-disabled: not-allowed !default;
$padding-large-horizontal:  16px !default;
$padding-base-horizontal:   12px !default;
$padding-small-horizontal:  10px !default;
$padding-base-vertical:     6px !default;
$padding-small-vertical:    5px !default;
$padding-large-vertical:    10px !default;
$font-size-large: 18px !default;
$font-size-small: 12px !default;
$line-height-large:         1.3333333 !default;
$line-height-small:         1.5 !default;
$gray-base:              #000 !default;
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee
$input-bg-disabled:              $gray-lighter !default;
$input-color-placeholder:        var(--bs-secondary-color) !default;

$line-height-base:        1.428571429 !default; // 20/14
$line-height-computed:    floor((14px * $line-height-base)) !default; // ~20px
$input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 2) !default;

$input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
$input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;
$screen-sm-min: 768px;
