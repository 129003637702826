@import './colors';

.node-content-wrapper {
	border: 1px solid #ffffff;
}

.node-content-wrapper-focused {
    background: $gray-2;
    border: 1px solid $gray-10;
}

.node-content-wrapper-active.node-content-wrapper-focused {
    background: $gray-2;
    border: 1px solid $gray-10;
}

.node-content-wrapper-active {
    background: None;
    border: 1px solid #ffffff;
    box-shadow: None;
}

.node-content-wrapper-active, .node-content-wrapper.node-content-wrapper-active:hover {
	background: $gray-2;
}

.node-content-wrapper.node-content-wrapper-focus:hover {
	background: $gray-3;
}

.node-drop-slot {
	height: 5px;
}