$text: #464646;
$white: #ffffff;
$gray-1: #f5f5f5;
$gray-2: #eeeeee;
$gray-2-opaque: rgba(238, 238, 238, 1);
$gray-3: #cccccc;
$gray-4: #aaaaaa;
$gray-5: #898989;
$gray-6: #676767;
$gray-7: #464646;
$gray-8: #3a3a3a;
$gray-9: #2d2d2d;
$gray-10: #202020;
$gray-11: rgba(161, 161, 161, 0.7);
$gray-12: #a5a5a5;
$gray-13: #f8f8f8;
$gray-14: #979797;
$gray-15: #E7E6E7;
$gray-16: #EFEFEF;
$gray-17: #E9E9E9;
$gray-18: #E9ECEF;

$black: #000;
$black-opaque-1: rgba(0, 0, 0, 1.0);
$black-opaque-2: rgba(0, 0, 0, 0.2);
$alice-blue: #F7F9FB;


$primary: #005EC4;
$primary-1: #d8dee3;
$primary-2: #c1d5e3;
$primary-3: #a2c4db;
$primary-4: #8cbdde;
$primary-5: #6db3e3;
$primary-6: #45a5e6;
$primary-7: #2196f3;
$primary-8: #0476d1;
$primary-9: #D9E7F6;
$primary-10: #003978;

$success: #198754;
$success-1: #ecf8ee;
$success-2: #c6ebcc;
$success-3: #a0deaa;
$success-4: #7ad188;
$success-5: #54c466;
$success-6: #2fb744;
$success-7: #279638;
$success-8: #1e752c;
$success-9: #5F7F3A;
$success-10: #BBE7C1;

$info: #5bc0be;
$info-1: #f0f9f9;
$info-2: #d2eded;
$info-3: #b4e2e1;
$info-4: #96d6d5;
$info-5: #78cbc9;
$info-6: #5bc0be;
$info-7: #4b9e9c;
$info-8: #3a7b79;
$info-9: #2a5857;

$warning: #f2bb05;
$warning-1: #fdf8e8;
$warning-2: #fbecba;
$warning-3: #f9e08d;
$warning-4: #f6d35f;
$warning-5: #f4c732;
$warning-6: #f2bb05;
$warning-7: #c79a05;
$warning-8: #9b7704;
$warning-9: #6e5503;

$danger: #dc3545;
$danger-1: #fbeee8;
$danger-2: #f4cebb;
$danger-3: #ecae8f;
$danger-4: #e58e62;
$danger-5: #de6e35;
$danger-6: #d74e09;
$danger-7: #b04008;
$danger-8: #893206;
$danger-9: #622405;
$danger-10: #D10E0E;

$light-danger: #FAE4E4;

// Orange color used for checksums work predictor
$orange_1: #f7cfb5;
$orange_2: #ff8818;

// Statement colors
$is: #ff6c43;
$bs: #ad3387;
$cf: #e73e6b;
$cv: #5d388c;
$other: #ffa600;

$default-background: #fafafa;
$default-box-shadow: #a1a1a1;

$default-border-color: var(--bs-border-color);;
$main-bg-color: $white;
$input-border-focus: $primary;
$gray-font: $gray-5;
$border-light: #E7E6E7;

$primary-button-blue: #2196f3;


$popup-transition-time: 250ms;
$input-label-color: $gray-5;
$input-text-color: $black;
$modal-title-color: $gray-5;

$text-white: $white;
$text-black: $black;

$modal-line-color: $gray-2;

$error-color: $danger;
$enable-color: $success;

// Colors for line items in formula editor
$highlight-red: #e85151;
$highlight-blue: #1a6ce6;
$highlight-lavender: #ca6bf9;
$highlight-orange: #dfa000;
$highlight-dark-green: #538c5f;
$highlight-pink: #e63a76;
$highlight-light-green: #10d01d;

$highlight-1: $highlight-red;
$highlight-2: $highlight-blue;
$highlight-3: $highlight-lavender;
$highlight-4: $highlight-orange;
$highlight-5: $highlight-dark-green;
$highlight-6: $highlight-pink;
$highlight-7: $highlight-light-green;

$category-yellow: #ffcd50;
$category-orange: #fc9d71;
$category-salmon: #ff7699;
$category-pink: #ff74dd;
$category-purple: #b07ffb;
$category-blue: #699bff;
$category-light-blue: #6ed1f9;
$category-blue-green: #6ae7c9;
$category-green: #8beb6a;
$category-text-color: $text;

$light-teal: #A3C9CB;

$portfolio-0: $info-5;
$portfolio-1: $category-green;
$portfolio-2: $category-blue-green;
$portfolio-3: $category-light-blue;
$portfolio-4: $category-blue;
$portfolio-5: $category-purple;
$portfolio-6: $category-pink;
$portfolio-7: $category-salmon;
$portfolio-8: $category-orange;
$portfolio-9: $category-yellow;

$cell-insufficient-red: #dfb2b2;
$cell-sufficient-green: #d0ece6;

$portfolios: $portfolio-0, $portfolio-1, $portfolio-2, $portfolio-3, $portfolio-4, $portfolio-5, $portfolio-6, $portfolio-7, $portfolio-8, $portfolio-9;

$graph-colors-active-blue: #3275C2;
$graph-colors-inactive-blue: #97B9E0;
$graph-colors-active-red: #D08F95;
$graph-colors-inactive-red: #E7C6C9;

$template-item-warning: #8C5F00;


:export {
    text: $text;
    white: $white;
    gray1: $gray-1;
    gray2: $gray-2;
    gray2Opaque: $gray-2-opaque;
    gray3: $gray-3;
    gray4: $gray-4;
    gray5: $gray-5;
    gray6: $gray-6;
    gray7: $gray-7;
    gray8: $gray-8;
    gray9: $gray-9;
    gray10: $gray-10;
    gray11: $gray-11;
    gray12: $gray-12;
    gray13: $gray-13;
    black: $black;
    blackOpaque1: $black-opaque-1;
    blackOpaque2: $black-opaque-2;
    aliceBlue: $alice-blue;
    primary: $primary;
    primary1: $primary-1;
    primary2: $primary-2;
    primary3: $primary-3;
    primary4: $primary-4;
    primary5: $primary-5;
    primary6: $primary-6;
    primary7: $primary-7;
    primary8: $primary-8;
    success: $success;
    success1: $success-1;
    success2: $success-2;
    success3: $success-3;
    success4: $success-4;
    success5: $success-5;
    success6: $success-6;
    success7: $success-7;
    success8: $success-8;
    success9: $success-9;
    info: $info;
    info1: $info-1;
    info2: $info-2;
    info3: $info-3;
    info4: $info-4;
    info5: $info-5;
    info6: $info-6;
    info7: $info-7;
    info8: $info-8;
    info9: $info-9;
    warning: $warning;
    warning1: $warning-1;
    warning2: $warning-2;
    warning3: $warning-3;
    warning4: $warning-4;
    warning5: $warning-5;
    warning6: $warning-6;
    warning7: $warning-7;
    warning8: $warning-8;
    warning9: $warning-9;
    danger: $danger;
    danger1: $danger-1;
    danger2: $danger-2;
    danger3: $danger-3;
    danger4: $danger-4;
    danger5: $danger-5;
    danger6: $danger-6;
    danger7: $danger-7;
    danger8: $danger-8;
    danger9: $danger-9;
    orange_1: $orange_1;
    orange_2: $orange_2;
    is: $is;
    bs: $bs;
    cf: $cf;
    cv: $cv;
    other: $other;
    defaultBackground: $default-background;
    defaultBoxShadow: $default-box-shadow;
    defaultBorderColor: $default-border-color;
    mainBgColor: $main-bg-color;
    inputBorderFocus: $input-border-focus;
    grayFont: $gray-font;
    primaryButtonBlue: $primary-button-blue;
    popupTransitionTime: $popup-transition-time;
    inputLabelColor: $input-label-color;
    inputTextColor: $input-text-color;
    modalTitleColor: $modal-title-color;
    textWhite: $text-white;
    textBlack: $text-black;
    modalLineColor: $modal-line-color;
    errorColor: $error-color;
    enableColor: $enable-color;
    highlightRed: $highlight-red;
    highlightBlue: $highlight-blue;
    highlightLavender: $highlight-lavender;
    highlightOrange: $highlight-orange;
    highlightDarkGreen: $highlight-dark-green;
    highlightPink: $highlight-pink;
    highlightLightGreen: $highlight-light-green;
    highlight1: $highlight-1;
    highlight2: $highlight-2;
    highlight3: $highlight-3;
    highlight4: $highlight-4;
    highlight5: $highlight-5;
    highlight6: $highlight-6;
    highlight7: $highlight-7;
    categoryYellow: $category-yellow;
    categoryOrange: $category-orange;
    categorySalmon: $category-salmon;
    categoryPink: $category-pink;
    categoryPurple: $category-purple;
    categoryBlue: $category-blue;
    categoryLightBlue: $category-light-blue;
    categoryBlueGreen: $category-blue-green;
    categoryGreen: $category-green;
    categoryTextColor: $category-text-color;
    lightTeal: $light-teal;
    portfolio0: $portfolio-0;
    portfolio1: $portfolio-1;
    portfolio2: $portfolio-2;
    portfolio3: $portfolio-3;
    portfolio4: $portfolio-4;
    portfolio5: $portfolio-5;
    portfolio6: $portfolio-6;
    portfolio7: $portfolio-7;
    portfolio8: $portfolio-8;
    portfolio9: $portfolio-9;
    cellInsufficientRed: $cell-insufficient-red;
    cellSufficientGreen: $cell-sufficient-green;
    portfolios: $portfolios;
    graphColorsActiveBlue: $graph-colors-active-blue;
    graphColorsInactiveBlue: $graph-colors-inactive-blue;
    graphColorsActiveRed: $graph-colors-active-red;
    graphColorsInactiveRed: $graph-colors-inactive-red;
    templateItemWarning: $template-item-warning;
}
