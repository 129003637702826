@mixin portfolio-variant ($category-color) {
    background-color: lighten( $category-color, 25% );
    &:before {
        background-color: $category-color;
    }
}

[class*="portfolio-label-"] {
    color: $text;

    &:before {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 4px 0 -2px;
        content: "\00a0";
        border-radius: 5px;
    }
}

@for $i from 1 through 10 {
    .portfolio-label-#{$i - 1} {
        @include portfolio-variant(nth($portfolios, $i));
    }
}