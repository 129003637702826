@import './colors';

table.financials-table {
    font-size: $font-size-table-cell;
    border-spacing: 0;
    border-collapse: collapse;
    margin-bottom: 0;
    font-family: Arial, sans-serif;
    color: black;

    thead {
        tr {
            th {
                min-width: 100px;
                text-align: right;
                background-color: white;
            }
            
        
            th:first-child {
                text-align: left;
            }
        
            .fa, .fas {margin-right: 10px;}
            border-bottom: solid 1px $gray-4;
        }
    }
    tbody {
        tr {
            background-color: $white;
            

            &:hover,
            &:hover td,
            &:hover th {
                background-color: $primary-1;
                cursor: pointer;
            }

            .graph-check.deselected {
                visibility: hidden;
            }

            &:hover .graph-check.deselected, .graph-check.selected {
                visibility: visible;
            }

            &.selected {
                background-color: $info-3;

                .source-row-td {
                    background-color: $info-3;
                }

                &:hover, &:hover td {
                    background-color: $info-3;
                }
            }

            &.mapped {
                color: $gray-4;
            }

            &.header {
                color: black;
                font-size: 14px;
                font-weight: bold;
                
                &:hover,
                &:hover td,
                &:hover th {
                    background-color: white;
                }

            }

            &.bold {
                font-weight: bold;
            }
        
            &:first-child  {
                text-align: left;
            }
        
        

            th:first-child {
                border-left: 0px none;
            }

            td {
                border: none;
                position: relative;
                text-align: right;
                
                line-height: 14px;
                padding: 4px 8px;
                
                &:first-child {
                    text-align:left;
                    padding-left: 15px;

                    
                    
                }

                .line-item-container {
                    display: flex;
                    .indent {
                        &.indent-1 {
                            flex-basis: 2em;
                        }
                        &.indent-2 {
                            flex-basis: 4em;
                        }
                        &.indent-3 {
                            flex-basis: 6em;
                        }
                        &.indent-4 {
                            flex-basis: 8em;
                        }
                    }

                    .line-item {
                        flex: 1 1 0;
                    }
                }
                

                &.no-padding {
                    padding: 0px;
                }
            }
        }
    }
}

.header-title {
    border: none;

    th {
        border-top: 1px solid $default-border-color;
    }
}

.table-sticky {
    width: auto;
    padding-bottom: 2px;
    padding-top: 2px;

    // in headers borders were replaced by shadows because for some
    // odd reason borders were not carried out :(
    thead {
        border: none;
        th {
            
            max-width: 160px;
            min-width: 100px;
            border: none;
            z-index: 2;
            color: black;
        }
        tr:first-child th {
            position: sticky;
            top: 0px;
            &:first-child {
                max-width: 300px;
                min-width: 300px;
                left: 0;
                box-shadow: none;
                z-index: 3;
            }
        }

        tr:nth-child(2) th {
            position: sticky;
            top: 60px;
            padding-bottom: 2px;
            padding-top: 2px;
        }
        tr:nth-child(3) th {
            position: sticky;
            top: 80px;
            padding-bottom: 2px;
            padding-top: 2px;
        }
        tr:nth-child(4) th {
            position: sticky;
            top: 100px;
            padding-bottom: 2px;
            padding-top: 2px;
        }
        tr:nth-child(5) th {
            position: sticky;
            top: 120px;
            padding-bottom: 2px;
            padding-top: 2px;
        }
        tr:nth-child(6) th {
            position: sticky;
            top: 140px;
            padding-bottom: 2px;
            padding-top: 2px;
        }

        tr:last-child th {
            box-shadow: none;
        }
    }

    tbody {
        td:first-child {
            // position: sticky;
            left: 0;
            // z-index: 0;
            box-shadow: none;
        }
    }
}