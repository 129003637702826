@import './scss/colors';
@import './scss/_variables';
@import './scss/bootstrap-custom';
@import './scss/modal';
@import 'scss/buttons';
@import "./scss/atomic";
@import "./scss/portfolio-labels";

@import "../libs/select2/scss/core";
@import "../libs/select2/select2-bootstrap";
@import '~handsontable/dist/handsontable.full.css';
@import '~@circlon/angular-tree-component/css/angular-tree-component.css';
@import "./scss/angular-tree-overrides";
@import "./scss/financials-table";
@import "./scss/ng-select";

app-component:empty + .preloader {
    opacity: 1;
    z-index: 100;
}

a {
    cursor: pointer;
}

body {
    overflow: hidden;
    background-color: $white;
}

input[type='checkbox'] {
    width: 16px;
    height: 16px;
}

#wrapper {
   display: flex;
   height: 100vh;

   &.has-header {
       height: calc(100vh - #{$main-nav-height});
   }
}

.content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

#main-wrapper {
    padding-top: 15px;
    overflow-y: auto;
    flex-grow: 2;
    min-width: 0;
    width: 100%;
}

#main {
    width: 100%;
    min-height: calc(100vh - 50px);
}


@media (min-width: 480px) {
    .navbar-nav {
        float: left;
        margin: 0;
    }

    .navbar-nav > li {
        float: left;
    }
}

.section-title {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 400;
}

.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.inner-content {
    padding: 15px;

    .title {
        border-bottom: 1px solid $default-border-color;
        padding-bottom: 10px;
        margin-top: -5px;
        margin-bottom: 15px;
        text-transform: uppercase;
        color: $modal-title-color;
        font-size: 12px;
    }
}

label {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: .3rem;
}

.help-block {
    font-size: 12px;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.loader {
    margin: auto auto;
    font-size: 3px;
    position: relative;
    border-top: 1.1em solid rgba(137, 137, 137, 0.3);
    border-right: 1.1em solid rgba(137, 137, 137, 0.3);
    border-bottom: 1.1em solid rgba(137, 137, 137, 0.3);
    border-left: 1.1em solid $primary;
    border-width: 1.1em !important;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;

    &--fit {
        margin: 0;
    }
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cell-input{
    position: relative;
    width: 90%;
    outline:none;
    border:none;
    margin-top: 8px;
    margin-left: 5px;
}

.selected-cell {
    border: 1.5px $primary solid !important;
    box-sizing: border-box !important;
    padding: 0 !important;
    height: 31px;
}

.pagination li a{
    cursor: pointer;
}

.stretcher {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toast-success {
    background-color: $brand-success;
}

.toast-error {
    background-color: $brand-danger;
}

.toast-info {
    background-color: $info-8;
}

.toast-warning {
    background-color: $warning-9;
}


.handsontable th, .handsontable td {
    font-size:13px;
    font-family: "Helvetica", "Arial", sans-serif;
}


/* Styles used for User Friendly Edit Option */

.human-readable-item:nth-of-type(7n+1) {
    color: $highlight-1;
    background-color: transparent;
}

.human-readable-item:nth-of-type(7n+1).highlighted {
    background-color: $highlight-1;
}

.human-readable-item:nth-of-type(7n+2) {
    color: $highlight-2;
    background-color: transparent;
}

.human-readable-item:nth-of-type(7n+2).highlighted {
    background-color: $highlight-2;
}

.human-readable-item:nth-of-type(7n+3) {
    color: $highlight-3;
    background-color: transparent;
}

.human-readable-item:nth-of-type(7n+3).highlighted {
    background-color: $highlight-3;
}

.human-readable-item:nth-of-type(7n+4) {
    color: $highlight-4;
    background-color: transparent;
}

.human-readable-item:nth-of-type(7n+4).highlighted {
    background-color: $highlight-4;
}

.human-readable-item:nth-of-type(7n+5) {
    color: $highlight-5;
    background-color: transparent;
}

.human-readable-item:nth-of-type(7n+5).highlighted {
    background-color: $highlight-5;
}

.human-readable-item:nth-of-type(7n+6) {
    color: $highlight-6;
    background-color: transparent;
}

.human-readable-item:nth-of-type(7n+6).highlighted {
    background-color: $highlight-6;
}

.human-readable-item:nth-of-type(7n+7) {
    color: $highlight-7;
    background-color: transparent;
}

.human-readable-item:nth-of-type(7n+7).highlighted {
    background-color: $highlight-7;
}

.clickable {
    cursor: pointer !important;
}

.clickable-text {
    cursor: pointer !important;
}

.clickable-text:hover {
    text-decoration: underline;
}

.borrower-name {
    font-size: 22px;
    margin: 0px;
    padding: 0 15px;
    display: inline-block;
}


.tile-container {
    /* border: 2px solid aliceblue; */
    box-shadow: 0px 0px 6px 1px rgba(148, 150, 153, 0.1);
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    background-color: #fff;
}

.tile-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: inherit;
}

.hidden {
    display: none;
}

// .svg-container {
//     display: inline-block;
//     position: relative;
//     width: 100%;
//     padding-bottom: 100%; /* aspect ratio */
//     vertical-align: top;
//     overflow: hidden;
// }
// .svg-content-responsive {
//     display: inline-block;
//     position: absolute;
//     top: 10px;
//     left: 0;
// }

.chart {
    height: 25rem;
    position: relative;
}

.chart > svg {
    width: 100%;
    height: 100%;
}

.align-right {
    text-align: right;
}

.no-padding {
    padding: 0;
}

.svg-legend {
    margin-top: 10px;
}

.select2-small {
    .select2-selection, .select2-container--bootstrap .select2-results__option {
        font-size: 10px;
        line-height: 13px;
    }
}

.ngui-auto-complete {
    z-index: 4;
    background-color: white !important;
}


ngui-auto-complete {
    position: relative !important;
    top: 3px !important;
 }

.top-margin {
    margin-top: 20px;
}

.bottom-margin {
    margin-bottom: 20px;
}


.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 5;
}

.loader__container {
    padding-top: 15%;

    h2 {
        text-align: center;
    }
}

div.navbar-fixed-top, div.navbar-fixed-bottom {
    z-index: 999;   // to avoid weird sentry error top nav stands out w/ modal issue
}

.bold {
    font-weight: bold;
}

.error-text {
    color: $error-color;
}

.label.badge-pill {
    border-radius:1em;
    margin:0 0.25em;
}

.btn.btn-pill {
    margin-right: 15px;
    border-radius: 25px;
    padding-right: 22px;
    padding-left: 22px;
}

.text-center {
    text-align:center;
}

.modal-loan-details {
    overflow-y: hidden !important;
}

.toggler {
    display: inline-block;
    padding-left: 15px;

    i {
        color: $primary;
        font-size: 24px;
        padding-left: 5px
    }

    i:hover {
        cursor: pointer;
    }

    p {
        display: inline-block
    }
}

.table-bordered, .table-bordered tr td, .table-bordered tr th {
    border: 1px solid #ccc;
}

.table tr td, .table tr th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: middle;
}

.center-text {
    text-align: center;
}


/**
    Bootstrap Overrides
**/


.table {
    // override the default bootstrap font color within tables to align with other text in the platform
    --bs-emphasis-color: $text;
}


// minor tweaks to bootstraps table-hover class
.table-hover {
    border: 1px solid #eeeeee;

    thead > tr > th {
        border-bottom-color: #CCCCCC;
    }

    tbody > tr:last-child > td {
        border-bottom: none;
    }
}

.navbar-nav > li > a {
    text-decoration: none;
}

.form-control, .form-select {
    min-height: 34px;
}

.input-group-text {
    background-color: $gray-18;
}

.custom-tooltip {
    --bs-tooltip-bg: var(--bs-primary);
    --bs-tooltip-border-radius: var(--bs-border-radius-lg);
    font-size: 1rem;
}
.toast-container .ngx-toastr {
    margin-top: 1rem;
}
