.cursor {
    &--pointer {
        cursor: pointer;
    }
}

.select {
    &--none {
        user-select: none;
    }
}
